import { create } from 'zustand';
import api from '../services/api';
import {
  Hybrid,
  IConfig,
  ISubscription,
  Manufacturer,
  Nutrition,
  Protection,
  ProtectionProduct,
  User,
} from './types';

type AppDataStore = {
  subscription: ISubscription;
  subscriptions: ISubscription[];
  user: User;
  isLoading: boolean;
  config: IConfig;

  listNutrition: Nutrition[];
  itemNutritionSelected: Nutrition;
  setItemNutritionSelected: (item: Nutrition) => void;

  listProtection: Protection[];
  itemProtectionSelected: Protection;
  setItemProtectionSelected: (item: Protection) => void;

  hybrids: Hybrid[];
  loadHybrids: () => Promise<void>;

  manufacturers: Manufacturer[];
  loadManufacturers: () => Promise<void>;

  nutritionManufacturers: Manufacturer[];
  loadNutritionManufacturers: () => Promise<void>;

  defensives: ProtectionProduct[];
  loadDefensives: () => Promise<void>;

  loadListNutrition: (id: string) => Promise<void>;
  loadListProtection: (id: string) => Promise<void>;
  setUser: (user: User) => void;
  setSubscription: (subscription: ISubscription) => void;
  setStep: (step: number) => void;
  setTerm: (term: boolean) => void;

  loadSubscriptions: () => Promise<void>;
  loadSubscription: (id: string) => Promise<void>;
  loadConfig: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;

  updateSubscription: (subscription: ISubscription) => void;
  clearSubscription: () => void;

  clearNutritionList: () => void;
  clearProtecionList: () => void;
  notAllowedNewSubscription: boolean;
};

export const useAppStore = create<AppDataStore>((set, get) => {
  return {
    subscription: {
      id: '',
      current_step: 0,
      haverst: '',
      user_id: '',
      name_creator_sub: '',
      tsr_id: null,
      producer_id: null,
      property_id: null,
      accept_terms: false,
      is_coupom: false,
      coupom_id: null,
      payment_id: null,
      term_id: null,
      seed_treatament_id: null,
      pre_sowing: undefined,
      sowing: undefined,
      coverage: undefined,
      leaf: undefined,
      specialties: undefined,
      herbicide: undefined,
      insecticide_caterpillars: undefined,
      insecticide_cicadas: undefined,
      fungicide: undefined,
      biodefencive: undefined,
      appointment_id: null,
      status: 'OPEN',
      user: null,
      tsrepresentative: null,
      producer: null,
      property: null,
      coupom: null,
      payment: null,
      term: null,
      hybridies_subscription: [],
      seed_treatment: null,
      protections: [],
      nutritions: [],
      appointment: null,
      created_at: undefined,
      updated_at: undefined,
    },
    listNutrition: [],
    listProtection: [],
    itemNutritionSelected: {} as Nutrition,
    itemProtectionSelected: {} as Protection,
    subscriptions: [],
    notAllowedNewSubscription: false,
    user: {} as User,
    isLoading: true,
    hybrids: [],
    manufacturers: [],
    nutritionManufacturers: [],
    defensives: [],
    config: {
      configSub: true,
      configPix: true,
      configCard: true,
      configCupom: true,
      safra: '2023/Inverno',
    },

    setUser: (user: User) => {
      set({ user });
    },

    setTerm: (term: boolean) => {
      const { subscription } = get();
      set({
        subscription: {
          ...subscription,
          accept_terms: term,
        },
      });
    },

    setStep: (step: number) => {
      const { subscription } = get();
      set({
        subscription: {
          ...subscription,
          current_step: step,
        },
      });
    },

    clearSubscription: () => {
      localStorage.removeItem('@GetapAPP:subscription_id');
      set({
        subscription: {
          id: '',
          current_step: 0,
          haverst: '',
          user_id: '',
          name_creator_sub: '',
          tsr_id: null,
          producer_id: null,
          property_id: null,
          accept_terms: false,
          is_coupom: false,
          coupom_id: null,
          payment_id: null,
          term_id: null,
          seed_treatament_id: null,
          pre_sowing: undefined,
          sowing: undefined,
          coverage: undefined,
          leaf: undefined,
          specialties: undefined,
          herbicide: undefined,
          insecticide_caterpillars: undefined,
          insecticide_cicadas: undefined,
          fungicide: undefined,
          biodefencive: undefined,
          appointment_id: null,
          status: 'OPEN',
          user: null,
          tsrepresentative: null,
          producer: null,
          property: null,
          coupom: null,
          payment: null,
          term: null,
          hybridies_subscription: [],
          seed_treatment: null,
          protections: [],
          nutritions: [],
          appointment: null,
          created_at: undefined,
          updated_at: undefined,
        },
      });
    },

    setSubscription: (subscription: ISubscription) => {
      localStorage.setItem('@GetapAPP:subscription_id', `${subscription.id}`);
      set({ subscription });
    },

    setIsLoading: (isLoading: boolean) => {
      set({ isLoading });
    },

    loadSubscriptions: async () => {
      set({ isLoading: true });
      const res = await api.get('/users/subscriptions');

      set({
        subscriptions: res.data,
        isLoading: false,
        notAllowedNewSubscription: res.data.some(
          (eachStatus: ISubscription) => eachStatus.status === 'OPEN'
        ),
      });
    },

    loadConfig: async () => {
      set({ isLoading: true });
      const res = await api.get('/system');
      set({
        isLoading: false,
        config: {
          configSub: res.data.is_subscription,
          configPix: res.data.pix_payment,
          configCard: res.data.card_payment,
          configCupom: res.data.cupom,
          safra: res.data.harvest,
        },
      });
    },

    loadHybrids: async () => {
      set({ isLoading: true });
      const res = await api.get('/system/hybrids');
      set({
        isLoading: false,
        hybrids: res.data,
      });
    },

    loadManufacturers: async () => {
      set({ isLoading: true });
      const res = await api.get('/system/manufacturers');
      set({
        isLoading: false,
        manufacturers: res.data,
      });
    },

    loadNutritionManufacturers: async () => {
      set({ isLoading: true });
      const res = await api.get('/system/nutritions');
      set({
        isLoading: false,
        nutritionManufacturers: res.data,
      });
    },

    loadDefensives: async () => {
      set({ isLoading: true });
      const res = await api.get('/system/defensives');
      set({
        isLoading: false,
        defensives: res.data,
      });
    },

    loadSubscription: async (id) => {
      set({ isLoading: true });
      const res = await api.get(`/subscription/${id}`);

      set({
        subscription: res.data,
        isLoading: false,
      });
    },

    updateSubscription: (subscription: ISubscription) => {
      set({ subscription });
    },

    loadListNutrition: async (id: string) => {
      set({ isLoading: true });
      const res = await api.get(`/subscription/list-nutrition/${id}`);

      set({
        listNutrition: res.data,
        isLoading: false,
      });
    },

    loadListProtection: async (id: string) => {
      set({ isLoading: true });
      const res = await api.get(`/subscription/list-protection/${id}`);

      set({
        listProtection: res.data,
        isLoading: false,
      });
    },

    setItemNutritionSelected: (item: Nutrition) => {
      set({
        itemNutritionSelected: item,
      });
    },

    setItemProtectionSelected: (item: Protection) => {
      set({
        itemProtectionSelected: item,
      });
    },

    clearNutritionList: () => {
      set({
        itemNutritionSelected: {} as Nutrition,
      });
    },

    clearProtecionList: () => {
      set({
        itemProtectionSelected: {} as Protection,
      });
    },
  };
});
